import React, { useEffect, useCallback } from 'react';
import './DetailTabs.scss';
import _ from 'lodash';
import { useLocation, useHistory } from "react-router-dom";
import classNames from 'classnames';

const URL_PARAM_TAB = 'tab';

const DetailTabs = ({ tabs, onTabSelect, activeTab }) => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const urlTab = (new URLSearchParams(location.search)).get(URL_PARAM_TAB);
    const tab = _.find(tabs, { value: urlTab });

    if (tab && tab.value !== activeTab.value) {
      onTabSelect(tab);
    }
  }, [location, tabs, onTabSelect, activeTab.value]);

  const handleTabClick = useCallback((tab) => {
    history.push({search: `?${URL_PARAM_TAB}=${tab.value}`});
    onTabSelect(tab);
  }, [history, onTabSelect]);

  return (
    <div className={"DetailTabs"}>
      {_.map(tabs, (formatTab, index) => {
        const isActive = formatTab.value === activeTab.value;

        return (
          <button
            type="button"
            className="DetailTabs__tab"
            key={formatTab.value || index}
            onClick={() => handleTabClick(formatTab)}
          >
            <div className="DetailTabs__tab-title">
              <span className={classNames(isActive ? "DetailTabs__tab-active" : "")}>
                {formatTab.label} {isActive ? ">" : ""}
              </span>
            </div>
          </button>
        );
      })}
    </div>
  );
};

export default DetailTabs;
