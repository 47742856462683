import React, { useState } from 'react'
import TopBar from '../../../components/utility/TopBar/TopBar';
import './AdminPanel.scss';

import TenantRfcForm from './components/tenantrfcs/TenantRfcForm';
import Customization from './components/Customization/Customization';
import DetailTabs from '../../../components/layout/DetailTabs/DetailTabs';

const adminTabs = [
    {value: 'rfc', label: 'RFC', Component: TenantRfcForm},
    {value: 'customization', label: 'Personalización', Component: Customization}
]

const AdminPanel = () => {

    const [ activeTab, setActiveTab ] = useState(() => adminTabs[0]);
    const { Component } = activeTab;

    return (
        <div className={"AdminPanel wind-scene"}>
            <TopBar
                title={ "Administración" }
                titleLinkBack
            />
   
            <div className="admin-tabs">
                <DetailTabs
                    tabs={adminTabs}
                    onTabSelect={setActiveTab}
                    activeTab={activeTab}
                />
                {Component && <Component className="component"/>}
            </div> 
            
        </div>
    )
}

export default AdminPanel;