import React, {useCallback, useEffect, useState, useContext} from "react";
import Modal from "../../../../../components/utility/Modal/Modal";
import WindSelect from "../../../../../components/formComponents/WindSelect/WindSelect";
import { ApiContext } from "../../../../../services/api/api-config";
import { getNotifier } from "../../../../../services/notifier";

const MainRfcModal = ({onClose, data, onSaveSuccess}) =>{

    const [rfc, setRfc] = useState();
    const [existingMainRfc, setExistingMainRfc] = useState()
    const api = useContext(ApiContext);

    const close = useCallback(() => {
        if (onClose) onClose()
    }, [onClose]);

    const save = useCallback(() => {
        try{
            if(existingMainRfc){
                api.tenantRfcs.update({id:existingMainRfc.value, params:{isMainRfc: false}})
            }
            api.tenantRfcs.update({id:rfc.value, params:{isMainRfc: true}})
                .then(()=>{
                    getNotifier().success("RFC principal actualizado")
                    if (onSaveSuccess) onSaveSuccess(); 
                })
                .catch(()=>{getNotifier().error("Error al guardar RFC")})
            close()
        }catch(e){
            getNotifier().error("Error: ", e)
        }
    },[api, rfc, existingMainRfc, close, onSaveSuccess])

    const handleChange = (e)=>{
        setRfc(e)
    }

    useEffect(() => {
        const mainRfc = data.find(rfc => rfc.isMainRfc);
        if (mainRfc) {
            setRfc({label: mainRfc.rfc, value: mainRfc.id});
            setExistingMainRfc({label: mainRfc.rfc, value: mainRfc.id})
        }
    }, [data]);

    return (
        <Modal title="RFC Principal"
        onClose={close}
        secondaryButtonAction={close}
        mainButtonAction={save}
        mainButtonText="Guardar"
        >
            <p><strong>Seleccione su RFC principal</strong></p>
            <p>Al seleccionar un RFC como principal se tomarán los datos de este para crear cotizaciones</p>
      
            <WindSelect 
                className={'selectChannels'}
                label="RFC" 
                name="rfc"
                options = {data.map(rfc => ({label: rfc.rfc, value: rfc.id}) )} 
                onChange={handleChange}
                value={rfc}
            />
      
        </Modal>
    )
}

export default MainRfcModal