import React, { useContext, useEffect, useCallback, useState } from 'react';
import useBoolean from '../../../../../hooks/useBoolean';
import {ApiContext} from "../../../../../services/api/api-config";
import useFormState from '../../../../../hooks/useFormState';
import SimpleCard from '../../../../../components/layout/SimpleCard/SimpleCard';
import WindInput from '../../../../../components/formComponents/WindInput/WindInput';
import { faPlug, faSave, faTrash, faHouse } from '@fortawesome/pro-light-svg-icons';
import {SecurityContext} from "../../../../../services/SecurityManager";
import IconButton from '../../../../../components/utility/IconButton/IconButton';
import Modal from '../../../../../components/utility/Modal/Modal';
import { satWsStatus, prepareTenantRfcForServer, getEmptyTenantRfc } from "../../../../../services/modelUtils/tenantRfcUtils"
import { getNotifier } from "../../../../../services/notifier"
import MainRfcModal from '../MainRfcModal/MainRfcModal';
import './TenantRfcForm.scss';
import Button from '../../../../../components/utility/Button/Button';

const TenantRfcForm = () => {

    const api = useContext(ApiContext);
    const { form, setForm, bindSimple } = useFormState( () => getEmptyTenantRfc() );
    const context = useContext(SecurityContext);
    const [ rfcs, setRfcs ] = useState([]);
    const [ idConnecting, setIdConnecting ] = useState(0);
    const [ idDeleting, setIdDeleting ] = useState(0);
    const [showingMainRfcModal, showMainRfcModal, hideMainRfcModal] = useBoolean(false);
    const [isUpdating, startUpdating, stopUpdating] = useBoolean();

    // ------ Load data ------
    useEffect(() => {
        if (context.isTenantUser())
            api.tenantRfcs.get()
                .then( apiData => setRfcs(apiData) );
            
    }, [ api, setForm, context]);

    const addRfc = useCallback(()=>{
        const method = 'create';
        let tenantRfcDocument = null;

        try {
            tenantRfcDocument = prepareTenantRfcForServer(form, false);
        } catch (err) {
            return getNotifier().error(err.message||err.detail);
        }
        
        const params = {
            params: tenantRfcDocument
        };
        
        stopUpdating();
        try {
            api.tenantRfcs[method](params).then((data) => {
                getNotifier().success('Se ha guardado correctamente. Por favor espere unos minutos para que sea validada la conexión.');
                
                api.satwsconnect['update']({ id: data.id, params }).then(  );
                api.tenantRfcs.get().then( apiData => setRfcs(apiData) );
            });
        }
        catch ( err ) {
            getNotifier().error('Ocurrió un error durante la ejecución.');
        } 
        
    },[form, api, stopUpdating]);
    
    // connect SATWS
    const handleConnectSatWS = (idRfc) => {
        setIdConnecting( idRfc );
    }

    const connectSatWS = () => {
        let tenantRfcDocument = {};
        try {
            tenantRfcDocument = prepareTenantRfcForServer(form, true);
        } catch (err) {
            return getNotifier().error(err.message||err.detail);
        }
        const params = {
            params: tenantRfcDocument
        };
        
        api.satwsconnect['update']({ id: idConnecting, params }).then( () => {
            getNotifier().success('Se ha enviado correctamente. Por favor espere unos minuitos para utilizar este RFC.');
            setIdConnecting(0);
            api.tenantRfcs.get().then( apiData => setRfcs(apiData) );
        } );
    }

    const cancellConnecting = () => {
        setIdConnecting( 0 );
    }

    // delete RFC
    const handleDelete = (idRfc) => {
        setIdDeleting(idRfc);
    }

    const deleteRfc = () => {
        api.satwsdelete.delete({ id: idDeleting })
            .then(()=>{
                cancellDeleting();
                getNotifier().success('El RFC ha sido eliminado 🗑');
                api.tenantRfcs.get().then( apiData => setRfcs(apiData) );
            });
    };

    const cancellDeleting = () => {
        setIdDeleting(0);
    }

    const handleMainRfcSaved = () => {
        api.tenantRfcs.get().then(apiData => {
            setRfcs(apiData);
        }).catch(() => {
            getNotifier().error('Ocurrió un error al actualizar la lista de RFCs.');
        });
    };
    

    return (
        <SimpleCard className='TenantRfcForm general-info' title="RFCs">

            <SimpleCard className='TenantRfcForm general-info' title="Agregar">
                <div className='fields'>
                    <WindInput
                            className='project-field'
                            placeholder={"Nuevo RFC*"}
                            type={'text'}
                            {...bindSimple('rfc')}
                        />
                    <IconButton
                        icon={faSave}
                        color={ 'black' }
                        onClick={startUpdating}
                    />
                </div>
                
            </SimpleCard>
            
            <SimpleCard className='TenantRfcForm general-info' title="Agregados">

                {
                    rfcs.map( rfc => <div className='fields' key={rfc.id}>
                        <WindInput
                            className='project-field'
                            placeholder={"RFC*"}
                            type={'text'}
                            value={rfc.rfc}
                            disabled={true}
                        />

                        {rfc.isMainRfc &&
                            <IconButton
                            icon={faHouse}
                            onClick={showMainRfcModal}
                            title=  "RFC Principal"
                            /> 
                        }

                        <IconButton
                            icon={faPlug}
                            color={ rfc.satWsId !== null && rfc.satWsStatus === satWsStatus.VALID ? 'success': 'danger' }
                            onClick={ () => { handleConnectSatWS(rfc.id) } }
                        />

                        <IconButton
                            icon={faTrash}
                            color={ 'danger' }
                            onClick={() => { handleDelete(rfc.id) }}
                        />
                    </div> )
                }

            </SimpleCard>

            <Button onClick={showMainRfcModal} className={"mainRfc"} outline>Seleccionr RFC Principal</Button>
            
            {/* ----- Password Modal ----- */}
            {isUpdating && <Modal
                title='Proporcione la clave CIEC'
                mainButtonAction={addRfc}
                mainButtonColor={'black'}
                mainButtonText={'Guardar y conectar'}
                secondaryButtonAction={stopUpdating}
                onClose={stopUpdating}
            >
                <WindInput
                    className='project-field'
                    placeholder={"CIEC*"}
                    type={'password'}
                    {...bindSimple('ciec')}
                />
            </Modal>}

            {/* ----- Password Modal ----- */}
            {!!idConnecting && <Modal
                title='Proporcione la clave CIEC'
                mainButtonAction={connectSatWS}
                mainButtonColor={'black'}
                mainButtonText={'Conectar'}
                secondaryButtonAction={cancellConnecting}
                onClose={cancellConnecting}
            >
                <WindInput
                    className='project-field'
                    placeholder={"CIEC*"}
                    type={'password'}
                    {...bindSimple('ciec')}
                />
            </Modal>}

            {/* ----- delete Modal ----- */}
            {!!idDeleting && <Modal
                title='Eliminar RFC'
                mainButtonAction={deleteRfc}
                mainButtonColor={'danger'}
                mainButtonText={'Eliminar'}
                secondaryButtonAction={cancellDeleting}
                onClose={cancellDeleting}
            >
                <p>¿ Estás seguro que quieres eliminar el RFC ? </p>
            </Modal>}

            {/* ----- Main Rfc Modal ----- */}
            {showingMainRfcModal && <MainRfcModal onClose={hideMainRfcModal} data={rfcs} onSaveSuccess={handleMainRfcSaved}/>}
        </SimpleCard>
    )
}

export default TenantRfcForm;