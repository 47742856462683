import jwt_decode from "jwt-decode";

const DAYS_TO_EXPIRE = 1;

/**
 * Refresh token if it is near to expire
 */
export const refreshToken = (api) => {
    let token = api.token;

    if(isNearToExpiration(token) && localStorage.getItem('remember')==='true'){
        generateNewToken(api);
    }
}

/**
 * This method validates if token is near to expire
 */
export const isNearToExpiration = (token) => {
    const decodedToken = jwt_decode(token);
    const currentDate = new Date();
    const hoursToExpire = (decodedToken.exp * 1000 - currentDate.getTime()) / (1000 * 60 * 24);
    
    return (hoursToExpire < (DAYS_TO_EXPIRE*24));
}

/*
*  This method creates a new token and stores it in the storage.
*/
export const generateNewToken = (api) => {
    api.refreshToken.create({}).then((response) => {
        const newToken = response?.token;

        if(newToken){
            api.setLoggedIn(newToken);
        }
    });
}