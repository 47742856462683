import React from 'react';
import ReactDOM from 'react-dom';
import 'normalize.css/normalize.css';
import App from './App';
//import * as serviceWorker from './serviceWorker';
import store from "./services/redux/store";
import {Provider} from "react-redux";

ReactDOM.render(
    <Provider store={store} >
        <App />
    </Provider>
    , document.getElementById('root'));

//serviceWorker.register();
