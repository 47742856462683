import React, {useState} from "react";
import { faFileImport } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../Button/Button";
import './DragAndDrop.scss'

const DragAndDrop = ({onSave}) =>{

    const [droppedFiles, setDroppedFiles] = useState(null);
    const [file, setFile] = useState(null);
    const [dragOver, setDragOver] = useState(false); 


    const handleDragOver = (e) => {
        e.preventDefault();
        setDragOver(true); 
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        setDragOver(true); 
    };

    const handleDragLeave = (e) => {
        setDragOver(false);  
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setDragOver(false)
        const files = e.dataTransfer.files[0];
        previewFile(files);
    };

    const previewFile = (file) => {

        if (file && (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg')) {
            setFile(file)
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setDroppedFiles({ dataUrl: reader.result, name: file.name });
            };
        } else {
            alert('El archivo debe tener formato PNG, JPG o SVG.');
        }
    };

    const deleteImage = () => {
        setDroppedFiles(null)
        setDragOver(false)
    } 

    return (
        <div>
            <div 
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            className={`drop-area ${dragOver ? 'drag-over' : ''}`}
            >

                {!droppedFiles && (
                    <div>
                        <FontAwesomeIcon icon={faFileImport} className="drop-icon"/>
                        <p className="drop-files-text">PNG, JPG, SVG</p>
                    </div>
                )}            

                {droppedFiles && (
                    <div className="drop-image-container">
                        <img src={droppedFiles.dataUrl} alt={droppedFiles.name} className="drop-image"/>
                                            
                    </div>
                )}
            </div>

            {droppedFiles && (
                <div className="action-btn-container">
                    <Button onClick={deleteImage} outline color={'danger'}>Eliminar</Button>
                    <Button className="action-btn" onClick={()=>{onSave(file, setDroppedFiles)}}>Guardar</Button>
                </div>
            )}


        </div>
    )
    
}

export default DragAndDrop