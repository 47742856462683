// - poner el tipo de documento con descripción
// - 

import { copyPropertyOrThrow, copyPropertyOrNull } from "../formUtils";

export const satWsStatus = {
    PENDING: "pending",
    VALID: "valid",
    INVALID: "invalid",
    DEACTIVATED: "deactivated",
    ERROR: "error",
};


export const prepareTenantRfcForServer = (form, update=false) => {
    let tenantRfc = {}
    if ( !update ) {
        copyPropertyOrThrow( form, tenantRfc, "rfc", "Por favor escribe un RFC" );
    }
    else {
        copyPropertyOrNull( form, tenantRfc, "rfc", "rfc" );
    }
    copyPropertyOrThrow( form, tenantRfc, "ciec", "Por favor escribe tu clave CIEC" );
    
    return tenantRfc;
}

export const convertTenantRfcToForm = (rfcData) => {
    return {
        ...rfcData,
    }
}

export const getEmptyTenantRfc = () => {
    return {
        id: null,
        rfc: null,
        ciec: null,
    };
}