import React, {useMemo, useCallback, useContext, useEffect, useState} from 'react';
import './ClientForm.scss';
import TopBar from "../../../components/utility/TopBar/TopBar";
import {paths} from "../../../services/routes/appRoutes";
import useFormState from "../../../hooks/useFormState";
import GeneralInfo from './components/GeneralInfo/GeneralInfo';
import {getNotifier} from '../../../services/notifier';
import {ApiContext} from '../../../services/api/api-config';
import {prepareClientForServer, clientDetailSGroups, prepareServerToForm, prepareClientForServerUpdate} from '../../../services/modelUtils/clientUtils';
import {useHistory} from 'react-router-dom';
import ContactInfo from './components/ContactInfo/ContactInfo';
import {useParams} from 'react-router-dom';
import TideEntitySelect from '../../../components/utility/TideEntitySelect/TideEntitySelect';
import FiscalInfo from './components/FiscalInfo/FiscalInfo';
import CenteredButtons from '../../../components/utility/CenteredButtons/CenteredButtons';
import SceneTitle from '../../../components/layout/SceneTitle/SceneTitle';
import Modal from '../../../components/utility/Modal/Modal';
import useBoolean from '../../../hooks/useBoolean';
import apiConfig from "../../../services/api/api-config";
import BrandingInfo from "./components/BrandingInfo/BrandingInfo";
import iconSuccess from "../../../assets/images/icon-success.png";
import ContactModal from './components/ContactModal/ContactModal';
import { SecurityContext } from '../../../services/SecurityManager';

const ClientForm = () => {
    const {id} = useParams();
    const {form, bindSimple, setForm} = useFormState(() => ({startDate: new Date()}));
    const history = useHistory();
    const api = useContext(ApiContext);
    const loadingId = `ClientForm.${id}`;
    const [createdSuccessfully, openCreated, closeCreated] = useBoolean(false);
    const [idCreated, setIdCreated] = useState();
    const [selectedFile, setSelectedFile] = useState(null);
    const [filePath, setFilePath] = useState(null);
    const downloadPath = apiConfig.host + '/download/uploads/';
    const [showingContactModal, showContactModal, hideContactModal] = useBoolean(false);
    const [tideEntitySelectKey, setTideEntitySelectKey] = useState(Date.now());
    const security = useContext(SecurityContext);
    // ----- Load Client data
    const loadClient = useCallback(() => {
        api.clients.get({id, loadingId, params: {sGroups: clientDetailSGroups}})
            .then(client => {
                setForm(prepareServerToForm(client))
                if (client.brandingLogo != null && client.brandingLogo.name.trim().length) {
                    setFilePath(downloadPath + client.brandingLogo.name);
                }
            })
            .catch(() => getNotifier().error('No se encontró el cliente solicitado'));
    }, [api, id, loadingId, setForm, setFilePath, downloadPath]);

    useEffect(() => {
        if (id) loadClient();
    }, [id, loadClient]);

    const handleOnSelectFile = useCallback((e) => {
        if (e != null && e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const size = (file.size / 1024 / 1024).toFixed(2);
            if (size > 2) {
                getNotifier()
                    .error('El peso del archivo solo puede ser hasta de 2 MB')
                return;
            }
            const reader = new FileReader();
            reader.onload = function (e) {
                setFilePath(e.target.result)
            };
            reader.readAsDataURL(file);
            setSelectedFile(file);
        } else {
            setSelectedFile(null);
            setFilePath(null)
        }
    }, []);

    const handleContactSaved = () => {
        setTideEntitySelectKey(Date.now()); // Update the key to the current timestamp
    };

    const handleSave = useCallback(() => {
        let client;
        const method = id ? 'update' : 'create';

        try {
            if (!id)
                client = prepareClientForServer({...form});
            else
                client = prepareClientForServerUpdate({...form});
        } catch (e) {
            return getNotifier().warning(e.message);
        }

        api.clients[method]({files: selectedFile, id, params: client, loadingId})
            .then((client) => {
                setIdCreated(client.id)
                openCreated();
            });
    }, [api, form, loadingId, id, openCreated, selectedFile]);

    const tools = useMemo(() => [
        {callback: () => window.history.back(), text: "Cancelar", outline: true},
        {callback: handleSave, text: "Guardar"}
    ], [handleSave]);

    // ----- Filter projects selector -----
    const mainContactFilters = useMemo(() => ({
        client: id,
        'order[updatedDate]': 'DESC',
        sGroups: ['user_read']
    }), [id]);

    const recreate = () => {
        window.location.reload(false);
    }

    const norecreate = () => {
        history.push(paths.clientDetail.replace(':id', idCreated));
    }

    const canCreate = security.canAdminUsers()
    const linkAddContact =  canCreate ? { text: "+ Nuevo contacto", callback: showContactModal } : null;

    return (
        <div className={" wind-scene"}>

            <TopBar/>

            <SceneTitle description={(!id) ? "Completa los campos para crear un nuevo cliente" : ""}>
                {(!id) ? "Nuevo cliente" : "Cliente"}
            </SceneTitle>

            <div className="single-title">Información general</div>
            <GeneralInfo
                bindSimple={bindSimple}
                form={form}
            />


            {/* Only captures the main contact for new clients */}
            <div className="single-title">Información de contacto</div>
            {!id ?
                <ContactInfo
                    bindSimple={bindSimple}
                    form={form}
                />
                :
                <div className='contact-block'>
                    <TideEntitySelect
                        key={tideEntitySelectKey}
                        className='main-contact-field'
                        entity='users'
                        placeholder={"Contacto principal"}
                        {...bindSimple('companyOwnerId')}
                        additionalFilters={mainContactFilters}
                        preload={true}
                        creatable = {canCreate}
                        action={linkAddContact}
                    />
                </div>
            }

            {showingContactModal && <ContactModal client={id} onClose={hideContactModal} onSaveSuccess={handleContactSaved}/>}

            <div className="single-title">Datos fiscales</div>
            <FiscalInfo
                setForm={setForm}
                fiscalData={form?.fiscalData}
            />

            <div className="single-title">Información de marca</div>
            <BrandingInfo
                setForm={setForm}
                bindSimple={bindSimple}
                filePath={filePath}
                handleOnSelectFile={handleOnSelectFile}
            />

            <CenteredButtons buttons={tools}/>

            {
                createdSuccessfully &&
                <Modal
                    onClose={closeCreated}
                    mainButtonAction={recreate}
                    mainButtonText="Crear nuevo cliente"
                    secondaryButtonAction={norecreate}
                    secondaryButtonText="Continuar después"
                >
                    <div className="center"></div>                        
                    <div className="center"><img src={iconSuccess} alt="Success"/></div>
                    <div className="center single-title mb-15">Se ha creado el cliente exitosamente</div>
                    <div className="center">¿Desea continuar creando clientes?</div>
                </Modal>
            }
        </div>
    );
}

export default ClientForm;
