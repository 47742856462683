import { faMinusSquare } from '@fortawesome/pro-light-svg-icons'
import React from 'react'
import { useCallback } from 'react'
import useCallbackCreator from 'use-callback-creator'
import WindInput from '../../../../../components/formComponents/WindInput/WindInput'
import WindSelect from '../../../../../components/formComponents/WindSelect/WindSelect'
import IconButton from '../../../../../components/utility/IconButton/IconButton'
import { unitCodeOptions } from '../../../../../services/modelUtils/taxDocumentUtils'

const InvoiceItemForm = ({ taxDocumentItem, onRemove, onChange, disabledEdit }) => {
    const handleChange = useCallbackCreator((name, value) => {
        onChange({ ...taxDocumentItem, [name]: value });
    }, [onChange, taxDocumentItem]);

    const handleTaxDocumentItemRemove = useCallback(() => {
        if ( !disabledEdit ) onRemove();
    }, [onRemove,disabledEdit]);

    const ivaAmount = ( taxDocumentItem.iva ? parseFloat(taxDocumentItem?.price||0) * parseFloat(taxDocumentItem?.unitAmount||0) * parseFloat(taxDocumentItem.iva ||0) : 0);
    const iepsAmount = ( taxDocumentItem.ieps ? parseFloat(taxDocumentItem?.price||0) * parseFloat(taxDocumentItem?.unitAmount||0) * parseFloat(taxDocumentItem.ieps ||0) / 100 : 0);
    const subtotal = (parseFloat(taxDocumentItem?.discount ? 
                parseFloat(taxDocumentItem?.price||0) * parseFloat(taxDocumentItem?.unitAmount||0) + parseFloat(taxDocumentItem?.discount) :
                parseFloat(taxDocumentItem?.price||0) * parseFloat(taxDocumentItem?.unitAmount||0)));
    
    const taxes = ivaAmount + iepsAmount;
    const total = subtotal + ivaAmount + iepsAmount - parseFloat(taxDocumentItem?.discount||0);

    return (
        <div className="InvoiceItemForm">
            <div className='fields'>
                <WindInput
                    className='project-field'
                    placeholder={"Identificador"}
                    type={'text'}
                    value={taxDocumentItem?.productIdentification||''}
                    onChange={handleChange('productIdentification')}
                    disabled={disabledEdit}
                />
                <WindInput
                    className='project-field'
                    placeholder={"Nombre del producto"}
                    type={'text'}
                    value={taxDocumentItem?.description||''}
                    onChange={handleChange('description')}
                    disabled={disabledEdit}
                />                
                <IconButton
                    className={'remove-button'}
                    onClick={handleTaxDocumentItemRemove}
                    data-tooltip={ disabledEdit?'No se puede remover el elemento':'Remover elemento'}
                    icon={faMinusSquare}
                    color={ disabledEdit? 'disabled': 'danger'}
                />
            </div> 
            <div className='fields'>
                <WindSelect
                    className={'project-field'}
                    placeholder={"Código de unidad"}
                    options={unitCodeOptions}
                    value={taxDocumentItem?.unitCode||''}
                    onChange={handleChange('unitCode')}
                    disabled={disabledEdit}
                />
                <WindInput
                    className='project-field'
                    placeholder={"Cantidad"}
                    type={'number'}
                    value={taxDocumentItem?.unitAmount||''}
                    onChange={handleChange('unitAmount')}
                    disabled={disabledEdit}
                />
                <WindInput
                    className='project-field'
                    placeholder={"Precio"}
                    type={'number'}
                    onChange={handleChange('price')}
                    value={taxDocumentItem?.price||''}
                    disabled={disabledEdit}
                />                
                <WindInput
                    className='project-field'
                    placeholder={"Descuento"}
                    type={'number'}
                    onChange={handleChange('discount')}
                    value={taxDocumentItem?.discount||''}
                    disabled={disabledEdit}
                />
            </div>
            <div className='fields'>
                <WindInput
                    className='project-field'
                    placeholder={"IVA %"}
                    type={'number'}
                    value={taxDocumentItem?.iva||''}
                    onChange={handleChange('iva')}
                    disabled={disabledEdit}
                />
                <WindInput
                    className='project-field'
                    placeholder={"IEPS %"}
                    type={'number'}
                    value={taxDocumentItem?.ieps||''}
                    onChange={handleChange('ieps')}
                    disabled={disabledEdit}
                />
                <WindInput
                    className='project-field'
                    placeholder={"Taxes"}
                    type={'number'}
                    value={taxes?.toFixed(2)||''}
                    inputProps={{readOnly: true}}
                    disabled={disabledEdit}
                />                
            </div>           
            <div className='fields'>
                <WindInput
                    className='project-field'
                    placeholder={"SubTotal"}
                    type={'number'}
                    value={subtotal?.toFixed(2)||0}
                    disabled={disabledEdit}
                />                
                <WindInput
                    className='project-field'
                    placeholder={"Total"}
                    type={'number'}
                    value={total?.toFixed(2)||0}
                    disabled={disabledEdit}
                />
            </div>
        </div>
    )
}

export default InvoiceItemForm