import React, { useCallback, useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom';
//import ToolBar from '../../../components/utility/ToolBar/ToolBar';
import TopBar from '../../../components/utility/TopBar/TopBar';
import useFormState from '../../../hooks/useFormState';
import { ApiContext } from '../../../services/api/api-config';
//import {faPlusSquare, faSave, faTrashCan, faEdit} from '@fortawesome/pro-light-svg-icons';
//import { useSelector } from 'react-redux';
import './InvoiceForm.scss';
import TideEntitySelect from '../../../components/utility/TideEntitySelect/TideEntitySelect';
import WindInput from '../../../components/formComponents/WindInput/WindInput';
import WindDatePicker from '../../../components/formComponents/WindDatePicker/WindDatePicker';
import WindSelect from '../../../components/formComponents/WindSelect/WindSelect';
import SimpleCard from '../../../components/layout/SimpleCard/SimpleCard';
import InvoiceItemForm from './components/InvoiceItemForm/InvoiceItemForm';
import classNames from 'classnames';
import _ from 'lodash';
import { convertTaxDocumentToForm, currencyOptions, getDocumentTaxesTotal, getEmptyTaxDocument, getTotalFromDocumentItems, paymentMethodsOptions, paymentTypesOptions, /*prepareTaxDocumentForServer,*/ taxDocumentDetailSGroups, taxDocumentStatusOptions, taxDocumentTypesOptions, xmlTextToObject } from '../../../services/modelUtils/taxDocumentUtils';
import useCallbackCreator from 'use-callback-creator';
import { getNotifier } from '../../../services/notifier';
import DragDropFile from '../../../components/formComponents/DragDropFile/DragDropFile';
import { useEffect } from 'react';
import {paths} from "../../../services/routes/appRoutes";
import { moneyFormatter } from '../../../services/currencyUtils';
import IconButton from '../../../components/utility/IconButton/IconButton';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { unPaidPaymentQuoteStatus, unPaidQuoteStatus } from '../../../services/modelUtils/quoteUtils';
import { clientTransactionSGroups } from '../../../services/modelUtils/transactionUtils';
import useBoolean from '../../../hooks/useBoolean';
import Modal from '../../../components/utility/Modal/Modal';

const InvoiceForm = () => {
    const api = useContext(ApiContext);
    const { form, setForm, bindSimple } = useFormState(() => getEmptyTaxDocument() );
    const { id } = useParams();
    const history = useHistory();

    // ------ Load data ------
    useEffect(() => {
        if (id)
            api.taxDocuments.get({ id, params:{sGroups: taxDocumentDetailSGroups}, customProp:'_' })
                .then(taxDocument => setForm(convertTaxDocumentToForm(taxDocument)));
    }, [id, api, setForm]);

    // -----
    let disabledEdit = true;
    if( !form.xmlFile ) {
        disabledEdit = false;
    }

    // ------ Save to server ------
//    const loadingId = 'InvoiceForm.create.p'+id;
//    const loading = useSelector(s=>!!s.loadingIds[loadingId]);

    /*const handleSave = useCallback(()=>{
        const method = id ? 'update' : 'create';
        let taxDocument = null;

        try {
            taxDocument = prepareTaxDocumentForServer(form);
        } catch (err) {
            return getNotifier().error(err.message||err.detail);
        }
        
        const params = {
            id,
            params: taxDocument
        };

        if(form.file)
            params.files = [form.file];

        api.taxDocuments[method](params).then(() => {
            getNotifier().success('Se ha guardado correctamente');
            history.push( paths.invoices );
        });
    },[form, api, history, id]); */

   /* const addInvoiceItem = useCallback(()=>{
        setForm(prevState => ({ ...prevState, taxDocumentItems: [...prevState.taxDocumentItems, {}] }));
    },[setForm]); */

    //const editInvoice = () => { alert("Editando factura") };

    // ---- Delete invoice -----
    // eslint-disable-next-line 
    const [isDeleting, startDeleting, stopDeleting] = useBoolean();
    const handleDelete = useCallback(()=>{
        api.taxDocuments.delete({ id: form.id })
            .then(()=>{
                stopDeleting();
                history.replace(paths.invoices);
                getNotifier().success('La factura ha sido eliminada 🗑');
            });
    },[api, history, form, stopDeleting]);

    // ------ Toolbar definition ------
   /* const tools = useMemo(()=>[
        { icon: faSave, callback: handleSave, text: "Guardar factura", disabled: loading  },
        { icon: faEdit, callback: editInvoice, text: "Editar factura", disabled: loading,  },
        { icon: faPlusSquare, callback: addInvoiceItem, text: "Añadir elemento", disabled: loading, testId: "add-invoice-item-button"  },
        { icon: faTrashCan, callback: startDeleting, text: "Eliminar factura", testId: "delete-invoice-button"},
    ],[handleSave, loading, addInvoiceItem, startDeleting]); */

    //const newClientParams = useMemo(()=>({sendAccess: false}), []);

    const handleDocumentProductChange = useCallbackCreator((index, taxDocumentItem) => {
        setForm(prevState => {
            const newTaxDocumentItems = [...prevState.taxDocumentItems];
            newTaxDocumentItems[index] = taxDocumentItem;
            return { ...prevState, taxDocumentItems: newTaxDocumentItems };
        });
    }, [setForm]);

    const handleDocumentTypesOptionsChange = useCallback((option) => {
        setForm(prevState => ({ ...prevState, taxDocumentType: option }));
    }, [setForm]);
    
    const handleFileChange = useCallback((file) => {
        setForm(prevState => ({ ...prevState, file }));
    }, [setForm]);

    const handleFileRemove = useCallback(() => {
        setForm(prevState => ({ ...prevState, file: null }));
    }, [setForm]);

    const taxesTotal = getDocumentTaxesTotal(form);

    const subTotalDocument = getTotalFromDocumentItems(form.taxDocumentItems) - taxesTotal; 

    const discount = form.discount ?  form.discount : 0;
    const totalDocument = subTotalDocument + taxesTotal - discount;
    
    useEffect(() => {
        const parseXML = async () => {
            if(form.file) {
                const taxDocument = await xmlTextToObject(form.file);
                setForm(prevState => ({ ...prevState, ...taxDocument }));
            }
        }

        parseXML();
    }, [form.file, setForm]);

    const addQuoteToInvoice = useCallback((quote) => {
        setForm(prevState => ({
            ...prevState,
            quotes: [ ...prevState.quotes, quote ]
        }));
    }, [setForm]);

    const onQuoteRemove = useCallback((quote) => {
        setForm(prevState => ({
            ...prevState,
            quotes: prevState.quotes.filter(q => q.id !== quote.id)
        }));
    }, [setForm]);

    const removeTaxDocumentItem = useCallbackCreator((index) => {
        setForm(prevState => {
            const newTaxDocumentItems = [...prevState.taxDocumentItems];
            newTaxDocumentItems.splice(index, 1);
            return { ...prevState, taxDocumentItems: newTaxDocumentItems };
        });
    }, [setForm]);

    const additionalQuoteFilters = {
        sGroups: clientTransactionSGroups,
        paymentStatus: unPaidPaymentQuoteStatus,
        status: unPaidQuoteStatus,
        pagination: false,
        "project.client": form?.client?.id
    };

    const quotes = form?.quotes||[];
    const quotesAmount = quotes.reduce((acc, quote) => acc + parseFloat(quote.total), 0);
    
    return (
        <div className={"InvoiceForm wind-scene"}>
            <TopBar
                title={ id ? "Editar factura" : "Nueva factura" }
                titleLinkBack
            />

            <SimpleCard className='general-info' title="XML">
                {form.xmlFile && 
                    <a 
                        className="xml-file-name" 
                        target="_blank" 
                        href={api?.appFiles.getUrl(form.xmlFile)}
                        rel="noreferrer"
                    > 
                        <b>Descargar XML</b>
                    </a>}

                {form.pdfFile && 
                    <a 
                        className="xml-file-name" 
                        target="_blank" 
                        href={api?.appFiles.getUrl(form.pdfFile)}
                        rel="noreferrer"
                    > 
                        <b>Descargar PDF</b>
                    </a>}

                <DragDropFile
                    placeholder={"Adjuntar XML"}
                    type={'text'}
                    file={form.xmlFile}
                    onChange={handleFileChange}
                    onRemove={handleFileRemove}
                    disabled={disabledEdit}
                />
            </SimpleCard>

            <SimpleCard className='general-info' title="Información general">
                <div className='fields'>
                    {
                        disabledEdit?
                        <WindInput
                            className='project-field'
                            placeholder={"Tipo de documento"}
                            type={'text'}
                            value={form.documentType.label}
                            disabled={disabledEdit}
                        />:
                        <WindSelect
                            className={'project-field'}
                            placeholder={"Tipo de documento"}
                            value={ form.documentType||'' }
                            options={taxDocumentTypesOptions}
                            onChange={handleDocumentTypesOptionsChange}
                        />
                    }
                    

                    <WindInput
                        className='project-field'
                        placeholder={"Folio"}
                        type={'text'}
                        {...bindSimple('folio')}
                        disabled={disabledEdit}
                    />

                    <WindInput
                        className='project-field'
                        placeholder={"UUID"}
                        type={'text'}
                        {...bindSimple('uuidFolioFiscal')}
                        disabled={disabledEdit}
                        showTooltip={true}
                    >                    
                    </WindInput>

                    <WindDatePicker
                        className='project-field'
                        placeholder={"Fecha"}
                        label={'Fecha'}
                        {...bindSimple('issuedAt')}
                        disabled={disabledEdit}
                    />
                </div>
                <div className='fields'>
                    <WindInput
                        className='project-field'
                        placeholder={"RFC"}
                        type={'text'}
                        {...bindSimple('rfc')}
                        disabled={disabledEdit}
                    />
                    <WindInput
                        className='project-field'
                        placeholder={"Emisor"}
                        type={'text'}
                        {...bindSimple('issuerName')}
                        disabled={disabledEdit}
                        showTooltip={true}
                    />
                    <WindInput
                        className='project-field'
                        placeholder={"Receptor"}
                        type={'text'}
                        {...bindSimple('receiverName')}
                        disabled={disabledEdit}
                        showTooltip={true}
                    />
                </div>
                <div className='fields'>
                    {
                        disabledEdit?
                        <WindInput
                            className='project-field'
                            placeholder={"Tipo de pago"}
                            type={'text'}
                            value={form.paymentType?.label}
                            disabled={disabledEdit}
                        />:
                        <WindSelect
                            className={'project-field'}
                            placeholder={"Tipo de pago"}
                            options={paymentTypesOptions}
                            {...bindSimple('paymentType')}
                        />
                    }

                    {
                        disabledEdit?
                        <WindInput
                            className='project-field'
                            placeholder={"Forma de pago"}
                            type={'text'}
                            value={form.paymentMethod?.label}
                            disabled={disabledEdit}
                        />:
                        <WindSelect
                            className={'project-field'}
                            placeholder={"Forma de pago"}
                            options={paymentMethodsOptions}
                            {...bindSimple('paymentMethod')}
                        />
                    }

                    {
                        disabledEdit?
                        <WindInput
                            className='project-field'
                            placeholder={"Estatus"}
                            type={'text'}
                            value={form.status?.label}
                            disabled={disabledEdit}
                        />:
                        <WindSelect
                            className={'project-field'}
                            placeholder={"Estatus"}
                            options={taxDocumentStatusOptions}
                            {...bindSimple('status')}
                        />
                    }
                    
                </div>
                <div className='fields'>
                    <WindSelect
                        className={'project-field'}
                        placeholder={"Moneda"}
                        options={currencyOptions}
                        {...bindSimple('currency')}
                        disabled={disabledEdit}
                    />
                    <WindInput
                        className='project-field'
                        placeholder={"Descuento"}
                        type={'number'}
                        {...bindSimple('discount')}
                        disabled={disabledEdit}
                    /> 
                    <WindInput
                        className='project-field'
                        placeholder={"Subtotal"}
                        type={'number'}
                        inputProps={{readOnly: true}}
                        value={subTotalDocument?.toFixed(2)}
                        disabled={disabledEdit}
                    />
                    <WindInput
                        className='project-field'
                        placeholder={"Total"}
                        type={'number'}
                        inputProps={{readOnly: true}}
                        value={totalDocument?.toFixed(2)}
                        disabled={disabledEdit}
                    />
                </div>
            </SimpleCard>

            <SimpleCard className='general-info' title="Cotizaciones">
                <div className='form-container'>
                    <div className='quote-instructions'>
                        Agrega una o más cotizaciones a las que corresponda esta factura
                    </div>

                    <div className='form-row'>
                        <TideEntitySelect
                            entity='quotes'
                            className='quotes-select'
                            placeholder='Buscar cotizaciones ...'
                            onChange={addQuoteToInvoice}
                            filterBy={'folio'}
                            preload
                            additionalFilters={additionalQuoteFilters}
                            labelCreator={(quote) => (
                                <span data-tooltip={`${quote.title}`}>{quote.folio || 'Sin folio'} - ${moneyFormatter(quote.total)}</span>
                            )}
                            value={null}
                        />
                        <div className='quotes-amount'>
                            Total de cotizaciones: <b>$ { moneyFormatter(quotesAmount||0) }</b>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className='table-container table-responsive'>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Cotización</th>
                                        <th>Total</th>
                                        <th>Proyecto</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {_.map(quotes, (quote, index) => (
                                        <tr key={quote.id || index}>
                                            <td>
                                                <IconButton
                                                    icon={faTrashAlt}
                                                    color='danger'
                                                    onClick={() => onQuoteRemove(quote)}
                                                />
                                            </td>
                                            <td>{quote.folio || 'Sin folio'}</td>
                                            <td>${moneyFormatter(Number(quote.total))}</td>
                                            <td>{quote.project?.name||''}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </SimpleCard>

            <SimpleCard 
                className={classNames('tax-document-item', form.items?.length === 1 && 'single-tax-document-item')} 
                title="Items">
                {_.map(form.taxDocumentItems, (taxDocumentItem, index)=>(
                    <InvoiceItemForm
                        key={taxDocumentItem.id||index}
                        onRemove={removeTaxDocumentItem(index)}
                        taxDocumentItem={taxDocumentItem}
                        onChange={handleDocumentProductChange(index)}
                        disabledEdit={disabledEdit}
                    />
                ))}
            </SimpleCard>

            {/* ----- Deleting Modal ----- */}
            {isDeleting && <Modal
                title='Eliminar factura'
                mainButtonAction={handleDelete}
                mainButtonColor={'danger'}
                mainButtonText={'Eliminar'}
                secondaryButtonAction={stopDeleting}
                onClose={stopDeleting}
            >
                <p>¿ Estás seguro que quieres eliminar la factura <strong>{form.id} - {form.folio}</strong> ?</p>
            </Modal>}
        </div>
    )
}

export default InvoiceForm