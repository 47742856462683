import moment from "moment";
import { getStatusIcon } from "../../components/utility/StatusIcon/StatusIcon";
import { moneyFormatter } from "../currencyUtils";
import { copyPropertyOrNull, copyPropertyOrThrow, copyPropertyValueOrThrow } from "../formUtils";
import { transactionStatus } from "./transactionUtils";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export const recurringPaymentListSGroups = [
    'recurring_payment_read_required_payment',
    'recurring_payment_read_id',
    'recurring_payment_read_amount',
    'recurring_payment_read_concept',
    'recurring_payment_read_active',
    'recurring_payment_read_first_payment_date',
    'recurring_payment_read_expiration_date',
    'recurring_payment_read_frequency_type',
    'recurring_payment_read_frequency_interval',
    'recurring_payment_read_repeat_in',
    'recurring_payment_read_period',
    'recurring_payment_read_last_payment',
    'transaction_read_transaction_date',
    'transaction_read_status',
];

export const recurringPaymentDetailSGroups = [
    'recurring_payment_read',
];

export const getRecurringPaymentColumns = (showModalHandler) => [
    {
        Header: 'Concepto',
        accessor: (rp) => {
            return(
            <Link to="#" onClick={()=>showModalHandler(rp)} data-tooltip={'Editar pago'}> {rp.concept} </Link>
            )
        }
    },
    {
        Header: 'Monto',
        id: 'amount',
        accessor: (rp) => `$ ${moneyFormatter(Number(rp.amount))}`,
    },
    {
        Header: 'Pago Requerido',
        id: 'required_payment',
        accessor: (rp) => (rp.requiredPayment? moment(rp.requiredPayment).format('DD/MM/YYYY'):null),
    },
    {
        Header: 'Pago Realizado',
        id: 'lastPayment',
        accessor: (rp) => (rp.lastPayment?.transactionDate ? moment(rp.lastPayment.transactionDate).format('DD/MM/YYYY'): '-'),
    },
    {
        Header: 'Periodicidad',
        id: 'recurrence',
        accessor: (rp) => periodTrans[rp.frequencyType],
    },
    {
        Header: 'Intervalo',
        id: 'frequencyInterval',
        accessor: (rp) => rp.frequencyInterval,
    },
    {
        Header: 'Repetir cada',
        id: 'repeatIn',
        accessor: (rp) => ( transRepeatIn(rp.repeatIn) ?? ''),
    },
    {
        Header: 'Expiración',
        id: 'expirationDate',
        accessor: (rp) => (rp.expirationDate ? moment(rp.expirationDate).format('DD/MM/YYYY'): 'Nunca'),
    },
    {
        Header: 'Pagado',
        id: 'paid',
        accessor: (rp) => getStatusIcon(rp.lastPayment?.status === transactionStatus.APPROVED),
    }
];

const periodTrans = {
    yearly: 'Anual',
    monthly: 'mensual',
    weekly: 'semanal',
    daily: 'diario',
}

const daysOfWeek = {
    monday: 'lunes',
    tuesday: 'martes',
    wednesday: 'miércoles',
    thursday: 'jueves',
    friday: 'viernes',
    saturday: 'sábado',
    sunday: 'domingo'
}

export const getFrequencyTypes = () => {
    return Object.keys(periodTrans).map((key) => ({
        value: key,
        label: periodTrans[key],
    }));
};

export const frequencyTypes = {
    yearly: 'yearly',
    monthly: 'monthly',
    weekly: 'weekly',
    daily: 'daily',
};

export const frequencyDescription = {
    yearly: 'años',
    monthly: 'meses',
    weekly: 'semanas',
    daily: 'días',
}

export const getMonthsList = () => {
    return [
        { value: 1, label: 'Enero' },
        { value: 2, label: 'Febrero' },
        { value: 3, label: 'Marzo' },
        { value: 4, label: 'Abril' },
        { value: 5, label: 'Mayo' },
        { value: 6, label: 'Junio' },
        { value: 7, label: 'Julio' },
        { value: 8, label: 'Agosto' },
        { value: 9, label: 'Septiembre' },
        { value: 10, label: 'Octubre' },
        { value: 11, label: 'Noviembre' },
        { value: 12, label: 'Diciembre' },
    ];
};

export const getRepeatInByFrequencyType = (frequencyType) => {
    if(!frequencyType || frequencyType===frequencyTypes.daily) 
        return [];

    
}

export const getDaysList = () => {
    return Object.keys(daysOfWeek).map((key) => ({
        value: key,
        label: daysOfWeek[key],
    }));
};

const transRepeatIn = (repeatIn) => {
    return daysOfWeek[repeatIn] ?? repeatIn ;
}

export const prepareRecurringPaymentForServer = (form) => {
    let recurringPayment = {}

    copyPropertyOrThrow( form, recurringPayment, "concept", "Por favor llena el campo de concepto" );
    copyPropertyOrThrow( form, recurringPayment, "amount", "Por favor llena el campo de monto" );
    copyPropertyOrThrow( form, recurringPayment, "firstPaymentDate", "Por favor selecciona la fecha del primer pago" );
    copyPropertyOrNull( form, recurringPayment, "expirationDate");
    copyPropertyOrThrow( form, recurringPayment, "frequencyInterval", "Por favor selecciona el intervalo de recurrencia" );
    copyPropertyValueOrThrow( form, recurringPayment, "frequencyType", "Por favor selecciona el tipo de recurrencia" );
    
    recurringPayment.frequencyInterval = Number(form.frequencyInterval);
    recurringPayment.amount= recurringPayment.amount.replace(/,/g, ""); 

    switch (recurringPayment.frequencyType) {
        case frequencyTypes.daily:
            recurringPayment.repeatIn = null;
            break;
        
        case frequencyTypes.weekly:
            copyPropertyValueOrThrow( form, recurringPayment, "repeatIn", "Por favor selecciona el día de la semana" );
            break;

        case frequencyTypes.monthly:
            copyPropertyValueOrThrow( form, recurringPayment, "onDay", "Por favor selecciona el día del mes" );
            recurringPayment.repeatIn = form.onDay?.value?.toString();
            break;

        case frequencyTypes.yearly:
            copyPropertyValueOrThrow( form, recurringPayment, "onDay", "Por favor selecciona el día del año" );
            copyPropertyValueOrThrow( form, recurringPayment, "onMonth", "Por favor selecciona el mes del año" );
            recurringPayment.repeatIn = `${form.onMonth.value.toString().padStart(2, '0')}-${form.onDay.value.toString().padStart(2, '0')}`;
            break;            

        default:
            break;
    }

    return recurringPayment;
}