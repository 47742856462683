import React from 'react';
import './WindSelect.scss';
import Select from "react-select";
import classNames from "classnames";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCaretDown } from '@fortawesome/pro-light-svg-icons';

const WindSelect = ({ value, onChange, options, placeholder, className, disabled, label, noLabel, inputProps={}, ...props }) => {
  return (
    <div className={classNames(className)}>
      { (!noLabel)?
        <div><label>{(label)?label:placeholder}</label></div>: null
      }
      
      <Select
        className={classNames('WindSelect', 'tide-react-select')}
        classNamePrefix='tide-react-select'
        name="windSelect"
        options={options}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        components={components}
        isDisabled={disabled}
        {...inputProps}
        {...props}
      />
    </div>
  );
}

export default WindSelect;

const components = {
  DropdownIndicator: (props) =>
      <button className='DropdownIndicator'>
        <FontAwesomeIcon className={classNames('caret', props?.selectProps?.menuIsOpen && 'open')} icon={faCircleCaretDown}/>
      </button>
}
